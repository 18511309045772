<template>
  <section class="cont">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>{{this.$route.path == '/add_ebookingroom_type_manage'?'EBooking':'PMS'}}</el-breadcrumb-item>
        <el-breadcrumb-item>房间管理</el-breadcrumb-item>
        <el-breadcrumb-item  to="/room_type_manage" v-if="this.$route.path == '/add_room_type_manage'">房型管理</el-breadcrumb-item>
        <el-breadcrumb-item  to="/ebooking_room_type_manage" v-if="this.$route.path == '/add_ebookingroom_type_manage'">房型管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.$route.query.id ? "编辑" : "新增" }}房型</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="primary" class="bg-gradient" @click="back">返回</el-button>
    </el-row>
    <el-row class="content-box">
      <div class="page">
        <div class="form-box">
          <div class="form-title">基本信息</div>
          <el-form :model="addroomform" :rules="rules" ref="addroomform" label-width="100px" style="margin-top: 30px">
            <el-form-item label="房型名称" prop="roomTypeName">
              <el-input v-model="addroomform.roomTypeName"  placeholder="请输入房间名称" maxlength="30"  ></el-input>
            </el-form-item>
            <el-form-item label="门市价" prop="roomTypePrice">
              <el-input v-model="addroomform.roomTypePrice" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"  placeholder="请输入房间门市价" >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="押金" prop="isDeposit">
              <el-radio-group v-model="addroomform.isDeposit" >
                <div style="display: flex;justify-content: center;align-items: baseline;">
                  <el-radio :label="true">需要</el-radio>
                  <el-radio :label="false">不需要</el-radio>
                  <el-input v-if="addroomform.isDeposit" v-model="addroomform.deposit" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" >
                    <template slot="append">元</template>
                  </el-input>
                  <span>前台默认销售价格</span>
                </div>

              </el-radio-group>
            </el-form-item>

            <el-form-item label="房间数量" v-if="$route.path == '/add_ebookingroom_type_manage'" prop="roomTotalNum">
              <el-input v-model="addroomform.roomTotalNum" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"  maxlength="5"   placeholder="请输入房间数量" >
                <template slot="append">个</template>
              </el-input>
            </el-form-item>
            <el-form-item label="床位数量" v-if="$route.path == '/add_ebookingroom_type_manage'" prop="bedNum">
              <el-input v-model="addroomform.bedNum" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"  maxlength="5"   placeholder="请输入床位数量" >
                <template slot="append">个</template>
              </el-input>
            </el-form-item>
            <el-form-item label="房型面积">
              <el-input v-model="addroomform.area" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"  maxlength="5"   placeholder="请输入房间面积" >
                <template slot="append">㎡</template>
              </el-input>
            </el-form-item>

            <el-form-item label="可住人数">
              <el-input v-model="addroomform.checkinNum" maxlength="2">
                <template slot="append">人</template>
              </el-input>
            </el-form-item>

          </el-form>
        </div>


        <div class="form-box">
          <div class="form-title">展示信息</div>
          <el-form :model="addroomform" :rules="rules" @submit.native.prevent ref="addroomimg" label-width="100px" style="width: 70%;margin-top: 30px">
            <el-form-item label="房间图片" prop="pmsRoomTypeImages">
              <div class="img-box" v-if="imgboxshow" style="display: flex;margin-bottom: 20px">
                <div class="img-list" v-for="(item,index) in addroomform.pmsRoomTypeImages" :key="index" style="position: relative;margin: 10px;">
                  <img  :src="item.imageUrl" style="width: 100px;height: 100px" />
                  <div class="delicon" @click="deleteimg(item,index)">×</div>
                </div>
              </div>
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :limit="5"
                  ref="upload"
                  :show-file-list="false"
                  :on-change="handleChange"
                  :auto-upload="false"
                  :multiple="false"
                  style="z-index: 999"
              >
                <i  class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="房型介绍">
              <el-input type="textarea" v-model="addroomform.remark" rows="4"  maxlength="1000" show-word-limit ></el-input>
            </el-form-item>


            <el-form-item label="房型标签">
              <el-tag
                  :key="tag"
                  v-for="tag in dynamicTags"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)">
                {{tag}}
              </el-tag>
              <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"

                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-tag"   @click="showInput" >+ 添加标签</el-button>
            </el-form-item>
          </el-form>

        </div>
        <div class="form-box">
          <div class="form-title">配套服务</div>
          <div style="display: inline-block" v-if="tagshow">
            <div class="tags" v-for="(item,index) in facilityIds" :key="index" >
              <img :src="item.icon" style="width: 100px;height: 100px"  />
              <p>{{item.name}}</p>
            </div>
          </div>

          <el-button style="display: block" class="bg-gradient" type="primary" @click="centerDialogVisible = true">添加配套服务</el-button>
        </div>
        <div class="btn-box">
          <el-button @click="back">取消</el-button>
          <el-button class="bg-gradient" type="primary" @click="addroomtype">提交</el-button>
        </div>
      </div>
    </el-row>
    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="50%"
        center>
      <el-form :model="addroomform" :rules="rules" ref="facilityform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="配套服务" prop="type">
          <div class="checkbox-box">
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="(item,index) in cities" :label="item.id" :key="index">
                <img :src="item.icon" style="width: 100px;height: 100px"  />
                <p>{{item.name}}</p>
              </el-checkbox>
            </el-checkbox-group>

          </div>
          <div class="btn-box">
            <el-button @click="centerDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="gettagshow">确定</el-button>
          </div>

        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
export default {
  data(){
    return{
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: true,
      hotelId:'',//酒店id
      tagshow:false,
      imgboxshow:false,
      centerDialogVisible:false,
      dynamicTags:[],//标签列表
      facilityIds:[],
      routerPath:'',
      facilityList:[],//设备列表
      inputVisible: false,
      inputValue: '',//标签输入框值
      limit:'',//请求数据数量
      emitdata:'',//修改传过来的数据
      page:'',//请求数据页数
      url:'',//新增编辑接口公用字段
      addroomform:{
        facilityIds:[],
        remark:'',
        roomTypeSource:'',//房型来源
        roomTypePrice:'',//房型基础价格
        roomTypeCode:'',//房型编码
        roomTypeName:'',//房型名称
        hotelId:'',//酒店ID
        sortNo:'',//排序号
        isDeposit:true,//需要押金
        deposit:0,//押金
        checkinNum:0,//可住人数
        area:0,//房型面积
        id:'',
        bedNum:'',//床位数量
        roomTypeTags:[],//房型标签
        roomTotalNum:'', // 房间总数量
        pmsRoomTypeImages:[],//房型照片
      },
      //校验
      rules: {
        roomTypeName:[
          { required: true, message: '请输入房型名称', trigger: 'blur' },
        ],
        roomTypePrice:[
          { required: true, message: '请输入门市价', trigger: 'blur' },
        ],

        roomTotalNum:[
          {required: true, message: '请输入房间总数量', trigger: 'blur'}
        ],
        pmsRoomTypeImages:[
          { required: true, message: '请上传房型图片', trigger: 'blur' },
        ],

      }
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    // is_sync  是否同步
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.page = 1
    this.addroomform.hotelId = this.hotelInfo.id
    if(this.$route.path == '/add_ebookingroom_type_manage'){
      this.addroomform.roomTypeSource = 'EBOOKING'
    }else{
      this.addroomform.roomTypeSource = 'PMS'
    }
    if (this.$route.query.id){
      this.getroomtypedetails()
    }
    this.getFacilityList()

  },
  methods: {
    //获取房型详情
    getroomtypedetails(){
      const url = pms.getroomtypedetails+this.$route.query.id
      const param = {}
      this.$axios.get(url, param).then(res => {
        if (res.success) {
          this.emitdata = res.records
          this.addroomform.area = this.emitdata.area
          this.addroomform.roomTypeName = this.emitdata.roomTypeName
          this.addroomform.roomTypePrice = this.emitdata.roomTypePrice
          this.addroomform.checkinNum = this.emitdata.checkinNum
          if (this.emitdata.remark != undefined){
            this.addroomform.remark = this.emitdata.remark
          }
          this.addroomform.isDeposit = this.emitdata.isDeposit
          this.addroomform.deposit = this.emitdata.deposit
          this.addroomform.bedNum = this.emitdata.bedNum
          this.addroomform.roomTotalNum = this.emitdata.roomTotalNum
          this.addroomform.pmsRoomTypeImages  =  this.emitdata.pmsRoomTypeImages
          this.cities.map(item=>{
            this.emitdata.facilityIds.map((items,i)=>{
              if (item.id == items){
                this.emitdata.facilityIds[i] = item
              }
            })
          })
          this.emitdata.facilityIds.forEach(item=>{
            this.checkedCities.push(item.id)
          })
          if (this.emitdata.roomTypeTags != ''){
            this.dynamicTags = this.emitdata.roomTypeTags.split(',')
          }
          this.facilityIds = this.emitdata.facilityIds
          this.tagshow = true
          this.imgboxshow = true
        }
      })

    },
    //复选框组
    handleCheckedCitiesChange(value) {
      this.addroomform.facilityIds = value
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },

    //标签
    handleClose(tag) {

      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    //显示选中的设施
    gettagshow(){
      this.facilityIds = []
      this.cities.forEach(item=>{
        this.addroomform.facilityIds.forEach(items=>{
          if (item.id == items){
            this.facilityIds.push(item)
          }
        })
      })


      this.tagshow = true
      this.centerDialogVisible = false
    },
    //获取房型设备列表
    getFacilityList(){
      const url = urlObj.getFacilityList
      const param = {condition:'',}
      this.$axios.post(url, param, 'json').then(res => {
        if (res.success) {
          this.cities = res.records
          this.cities.map(item=>{
            item.icon = item.icon && window.getResourcesHost() + item.icon
          })
        }
      })
    },

    //图片上传
    handleChange(file) {

      this.file = file.raw
      const url = urlObj.uploadDetailFile;
      let param = new FormData();
      param.append("file", this.file); // 文件
      param.append('bizId','default')
      param.append('module','event')
      param.append('menu','distributor')
      param.append('func','image')
      param.append('category','image')
      param.append('isThumb',true)
      this.$axios.post(url, param, 'file')
          .then((res) => {
            this.imgboxshow = false
            let imglist = []
            imglist.push(res.records)
            imglist.forEach(item=>{
              item.image_url = window.getResourcesHost()+item.location+'/'+item.formatName
              this.addroomform.pmsRoomTypeImages.push({imageUrl:item.image_url,id:item.id})
            })
            this.imgboxshow = true

          });
    },
    //删除图片
    deleteimg(item,i) {
      this.$axios.get(`/pms/pmsRoomTypeImage/delete/${item.id}`,{}).then(res => {
        console.log(res);
        if(res.success) {
          this.$message({
            type:'success',
            message:'图片删除成功!'
          })
          this.getroomtypedetails()
        }
      }).catch(err => {
        console.log(err);
      })
      // const url = urlObj.deleteUpload;
      // let param = {
      //   ids:item.id
      // };
      // this.$axios.get(`/pms/pmsRoomTypeImage/deletePmsRoomTypeImages/${item.id}`).then((res) => {
      //   if (res.status == 200){
      //     this.$message({
      //       message: "图片删除成功!",
      //       type: "success",
      //     });
      //   }
      //   this.imgboxshow = false
      //   this.addroomform.pmsRoomTypeImages.splice(i,1)
      //   if (this.addroomform.pmsRoomTypeImages.length == 0){
      //     this.imgboxshow = false
      //   }
      //   this.imgboxshow = true
      // });
    },
    //返回上一页
    back(){
      this.$router.go(-1)
    },
    //添加房型
    addroomtype(){
      const p1 = new Promise((resolve,reject)=>{
        this.$refs['addroomform'].validate(valid=>{
          if(valid) resolve()
        })
      })
      const p2 = new Promise((resolve,reject)=>{
        this.$refs['addroomimg'].validate(valid=>{
          if(valid) resolve()
        })
      })
      Promise.all([p1,p2]).then(()=>{
        this.addroomform.roomTypeTags = this.dynamicTags.join(',')
        if (this.$route.query.id){
          this.addroomform.id = this.$route.query.id
          this.addroomform.facilityIds = []
          this.facilityIds.forEach(item=>{
            this.addroomform.facilityIds.push(item.id)
          })
          this.url = pms.editroomtype;
        }else{
          this.url = pms.addroomtype;
        }
        let param = this.addroomform
        this.$axios.post(this.url, param, 'json').then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "保存成功!",
              type: "success",
            });
            this.back()
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        }).catch(error=>{
          this.$message.error(error.data.message)
        })
      })

    },
    // //修改房型
    // editroomtype(){
    //   const p1=new Promise((resolve,reject)=>{
    //     this.$refs['addroomform'].validate(valid=>{
    //       if(valid) resolve()
    //     })
    //   })
    //
    //   const p2=new Promise((resolve,reject)=>{
    //     this.$refs['addroomimg'].validate(valid=>{
    //       if(valid) resolve()
    //     })
    //   })
    //   Promise.all([p1,p2]).then(()=>{
    //     this.addroomform.roomTypeTags = this.dynamicTags.join(',')
    //     const url = pms.editroomtype;
    //     let param = this.addroomform
    //     this.$axios.post_query(url, param).then((res) => {
    //       if (res.status == 200) {
    //         this.$message({
    //           message: "添加成功!",
    //           type: "success",
    //         });
    //         this.back()
    //       } else {
    //         this.$message({
    //           message: res.message,
    //           type: "error",
    //         });
    //       }
    //     });
    //
    //   })
    // },


  },
  watch: {
    hotelInfo: {
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.getFacilityList()
          if (this.$route.query.id){
            this.getroomtypedetails()
          }
        }
      },
      deep: true
    },
    $route:{
      handler(router){
        this.routerPath = router.path
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.page{
  width: 100%;
  height: 100%;
}
.title{
  font-size: 16px;
  border-bottom: 1px solid #dedede;
  padding: 10px 0;
}

.form-box{
  /*padding: 30px;*/
  /*background: #f7fbfe;*/
  margin-top: 50px;
  max-width: 1200px;
}
.form-box:first-child{
  margin-top: 0px;
}
.form-title{
  font-size: 16px;
  font-weight: 900;
}
.btn-box{
  margin-top: 20px;
  text-align: right;
  max-width: 1260px;
}
.delicon{
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  line-height: 10px;
}
.poster{
  width: 120px;
  height: 160px;
  border-radius: 10px;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.checkbox-box{
  display: inline-block;
}
.item{
  float: left;
  text-align: center;
  width: 25%;
}
.tags{
  float: left;
  padding: 10px;
  text-align: center;
}

</style>
